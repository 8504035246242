body {
  padding: 0;
  margin: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css or App.css */
html,
body {
  direction: rtl;
  text-align: right;
}

.container {
  direction: rtl;
  text-align: right;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: "Tajawal", sans-serif;
}
